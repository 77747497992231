export const appointments = [
  {
    title: "Customer Workshop",
    startDate: new Date(2022, 10, 1, 8.3),
    endDate: new Date(2022, 10, 1, 10),
    allDay: true,
    id: 39,
    location: "Room 1",
  },
  {
    allDay: false,
    endDate: new Date(2022, 10, 5, 8, 30),
    id: 40,
    notes: "asdasd",
    startDate: new Date(2022, 10, 5, 10, 30),
    title: "deneme",
  },
];
